<template>
  <v-card class="work-items">
    <v-card-title>
      <span>{{$t('t.ActionsToDo')}}</span>
    </v-card-title>
    <v-card-text class="card-text-full-height">
      <v-row class="full-height">
        <v-col
          cols="8"
          class="full-height"
        >
          <v-row>
            <v-spacer />
            <v-col cols="5">
              <v-btn
                color="primary"
                class="border-radius"
                block
                @click="navigateToDunningReminder"
              >
                <span class="font-weight-bold">{{dunningCount}}</span>
                <v-icon class="ml-2">{{$icon('i.DunningReminders')}}</v-icon><span class="ml-2">{{$t('t.Dunnings')}}</span>
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn
                color="primary"
                class="border-radius"
                block
                @click="navigateToCalendarReminder"
              >
                <span class="font-weight-bold">{{reminderCount}}</span>
                <v-icon class="ml-2">{{$icon('i.CalendarBasedReminders')}}</v-icon> <span class="ml-2">{{$t('t.Reminders')}}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            dense
            class="second-row-full-height flex-nowrap"
          >
            <v-col
              cols="auto"
              class="full-height overflow-y-auto pr-3"
            >
              <v-list
                flat
                dense
                v-if="defaultTypes.length"
              >
                <v-list-item-group
                  v-model="selectedTypes"
                  multiple
                >
                  <v-list-item
                    v-for="t in defaultTypes"
                    :key="t.id"
                    :value="t.id"
                    style="padding-left: 0px;"
                    class="border-radius"
                  >
                    <div class="d-flex background-plain item pa-1 align-center mb-1">
                      <bool-to-icon
                        class="primary--text mr-4"
                        :value="!selectedTypes.length || selectedTypes.includes(t.id)"
                        :true-value-icon="'i.CheckedCircle'"
                        :false-value-icon="'i.UncheckedCircle'"
                      />
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            :class="{'primary--text':!selectedTypes.length || selectedTypes.includes(t.id)}"
                          >
                            {{workItemTypeIcons[t.code]}}
                          </v-icon>
                        </template>
                        {{t.name}}
                      </v-tooltip>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-menu
                :close-on-content-click="false"
                v-if="customTypes.length"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="item"
                  >
                    <span v-if="selectedTypes.length">{{selectedCustomTypesCount}} / {{customTypes.length}}</span>
                    <span v-else>{{customTypes.length}}</span>
                    <span class="ml-1">{{$t('t.More')}}</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-list
                    flat
                    dense
                    class="border-radius"
                  >
                    <v-list-item-group
                      v-model="selectedTypes"
                      multiple
                    >
                      <v-list-item
                        v-for="t in customTypes"
                        :key="t.id"
                        :value="t.id"
                        class="border-radius"
                      >
                        <div class="d-flex pa-1 align-center">
                          <bool-to-icon
                            class="primary--text mr-4"
                            :value="!selectedTypes.length || selectedTypes.includes(t.id)"
                            :true-value-icon="'i.CheckedCircle'"
                            :false-value-icon="'i.UncheckedCircle'"
                          />
                          <span :class="{'primary--text':!selectedTypes.length || selectedTypes.includes(t.id)}">{{t.name}}</span>
                        </div>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            <v-col class="full-height overflow-x-auto">
              <v-list class="filtered-accounts border-radius">
                <v-list-item
                  v-for="item in workItems"
                  :key="item.account.id"
                  link
                  @click="navigateToWorkItems(item)"
                  class="border-radius"
                >
                  <div class="d-flex my-1">
                    <div class="align-self-center  pa-1">
                      <v-icon :color="`#${item.workItem.color}`">{{workItemTypeIcons[item.workItem.type.code] || workItemTypeIconDefault }}</v-icon>
                    </div>
                    <div class="ml-4">
                      <account-ref
                        :id="item.account.id"
                        :icon-props="{small: false}"
                      />
                      <span class="font-weight-bold">{{truncateWithEllipsis(item.workItem.description, 200)}} {{workItemDescription(item)}}</span>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols=4
          class="full-height"
        >
          <v-row class="full-height flex-column flex-nowrap overflow-y">
            <v-col class="text-center pb-0">
              <span class="title">{{$t('t.AmountToRecover')}}</span>
            </v-col>
            <v-col class="pa-0 mt-n2">
              <graph
                v-if="recoveryAmount"
                :options="radialBarOptions"
                :series="radialBarSeries"
                type="radialBar"
                width="100%"
              />
            </v-col>
            <v-col class="text-center mt-n4">
              <div class="primary--text title font-weight-bold">{{formatCurrency(recoveryAmount.taskCovered)}}</div>
              <div class="title font-weight-light">{{formatCurrency(recoveryAmount.taskOpenBalance)}}</div>
            </v-col>
            <v-col class="text-center pt-0">
              <reward :score="recoveryAmount.amountRecoveredPercent" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import recentsController from '@/recentsController'
import themeController from '@/themeController'
import { GlobalSearch } from '@/pages/search/controllers'

export default {
  components: {
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    BoolToIcon: () => import('@/components/bool-to-icon'),
    Graph: () => import('@/components/graph'),
    Reward: () => import('./reward')
  },
  data () {
    return {
      selectedTypes: [],
      workItems: [],
      defaultTypes: [],
      customTypes: [],
      dunningCount: 0,
      reminderCount: 0,
      recoveryAmount: {},
      workItemTypeIconDefault: this.$icon('i.GenericTask'),
      workItemTypeIcons: {
        email: this.$icon('i.Email'),
        phone: this.$icon('i.Phone'),
        disputePastTargetDate: this.$icon('i.Disputes'),
        brokenPromiseToPay: this.$icon('i.Promises'),
        invoiceContestation: this.$icon('i.Bell'),
        invoiceValidation: this.$icon('i.Bell'),
        collaborationTaskPastTargetDate: this.$icon('i.CollaborationTaskTypes'),
        creditStatusChangedTo: this.$icon('i.CreditStatusChanged'),
        riskCategoryChangedTo: this.$icon('i.RiskCategoryChanged')
      }
    }
  },
  computed: {
    selectedCustomTypesCount () {
      return this.lodash.intersection(this.selectedTypes, this.customTypes.map(t => t.id)).length
    },
    radialBarSeries () {
      return [Math.round(this.recoveryAmount.amountRecoveredPercent) || 0]
    },
    radialBarOptions () {
      return {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        colors: [themeController.primary],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: 'var(--bg-plain)',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#000000',
                opacity: 0.16,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          opacity: 1
        },
        labels: ['Average Results']
      }
    }
  },
  methods: {
    workItemDescription (item) {
      return item.workItemCount <= 1 ? '' : '  + ' + (item.workItemCount - 1) + ' ' + this.$t('t.OtherActions')
    },
    navigateToWorkItems (item) {
      recentsController.goToStaticTab({
        documentType: 'accounts',
        documentId: item.account.id
      }, 'to-do')
    },
    async query () {
      const e = await this
        .$http()
        .versionHashed()
        .post('/core/v6/dashboard/agent/work-items', { workItemTypes: this.selectedTypes, showAll: this.showAll })

      this.workItems = e.data.workItems
      this.defaultTypes = e.data.workItemTypes.filter(wit => wit.code)

      this.customTypes = e.data.workItemTypes.filter(wit => !wit.code)
      this.dunningCount = e.data.dunningCount
      this.reminderCount = e.data.reminderCount
      this.recoveryAmount = e.data.recoveryAmount
    },
    navigateTo (ty) {
      this.$router.push('/search').then(() => GlobalSearch.activeSearch.setDocumentType(ty).execute(true))
    },
    navigateToDunningReminder () {
      this.navigateTo('dunning-reminders')
    },
    navigateToCalendarReminder () {
      this.navigateTo('calendar-based-reminders')
    }
  },
  watch: {
    selectedTypes (v) {
      if (v.length === this.defaultTypes.length + this.customTypes.length) {
        this.selectedTypes = []
      }

      this.query()
    },
    showAll: {
      handler (v) {
        if (typeof v !== 'undefined') {
          this.query()
        }
      },
      immediate: true
    }
  },
  props: {
    showAll: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.work-items
  display flex
  flex-direction column
  position relative
  height 100%

.filtered-accounts
  height 100%
  overflow auto

.full-height
  height 100%

.card-text-full-height
  height calc(100% - 25px)

.second-row-full-height
  height calc(100% - 60px)

.overflow-y
  overflow-y auto

.nudge-top
  margin-top 1px

.item
  border-radius var(--border-radius)
</style>
